<script setup lang=ts>
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '~/components/ui/avatar'


import {
  BadgeCheck,
  Bell,
  ChevronRight,
  ChevronsUpDown,
  Settings,
  BadgeDollarSign,
  LogOut,

  PieChart,
  UtensilsCrossed,
  Sparkles,
  ListOrdered,
  MessageSquareWarning,
  LayoutDashboard,
  Menu,
  MessageCircle
} from 'lucide-vue-next'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { Separator } from '~/components/ui/separator'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarTrigger
} from '~/components/ui/sidebar'
import type { RoutesNamesList } from '@typed-router/__routes'
import type { Component } from 'vue'

interface Menu {
  title: string;
  routeName?: RoutesNamesList | undefined
  icon?: Component
  children?: Menu[]
}
const mainMenu: Menu[] = [
  { title: "Dashboard", routeName: "home", icon: LayoutDashboard },
  {
    title: "Gestionnaire", routeName: undefined, icon: PieChart,
    children: [
      { title: "Ingrédients", routeName: "manager-ingredients" },
      { title: "Plats", routeName: "manager-foods" },
      { title: "Menus", routeName:"manager-menu" },
      { title: "Tables", },
      { title: "Mouvements", },
      { title: "Inventaire", },
    ]
  },
  { title: "Commandes", icon: ListOrdered },
  { title: "Transactions", icon: BadgeDollarSign },
  { title: "Messagerie", icon: MessageCircle },
  { title: "Rapports", icon: MessageSquareWarning },
]

const supabaseUser = useSupabaseUser()
const userInfo = ref<Database["public"]["Tables"]["profiles"]["Row"]>()
async function loadUser() {
  const supabase = useSupabaseClient()
  const user = useSupabaseUser().value
  const { data } = await supabase.from("profiles").select("*").eq("id", user?.id ?? "").single();
  if (data)
    userInfo.value = data
}
loadUser()
// This is sample data.
const data = {
  // user: userInfo.value,


  main: mainMenu
  ,


}
const route = useRoute();
const collapsibleElements = ref<string[]>([])
const isElementOpen = computed(() => (element: string) => collapsibleElements.value.includes(element));

function updateOpen(params: string) {
  if (collapsibleElements.value.includes(params)) {
    collapsibleElements.value = collapsibleElements.value.filter(item => item !== params);
  }
  else {
    collapsibleElements.value.push(params)
  }
}
</script>

<template>
  <SidebarProvider>
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <SidebarMenuButton size="lg"
                  class="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
                  <div
                    class="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                    <UtensilsCrossed class="size-6" />

                  </div>
                  <div class="grid flex-1 text-left text-sm leading-tight">
                    <span class="truncate font-semibold"> Go resto </span>
                    <span class="truncate text-xs">manager panel</span>
                  </div>
                </SidebarMenuButton>
              </DropdownMenuTrigger>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup class="">
          <SidebarGroupLabel>Accueil</SidebarGroupLabel>
          <SidebarMenu>
            <span class="" v-for="item in data.main" :key="item.title">
              <Collapsible v-if="item.children?.length ?? 0 > 0" class="group/collapsible">
                <SidebarMenuItem>
                  <CollapsibleTrigger as-child>
                    <SidebarMenuButton :tooltip="item.title">
                      <component :is="item.icon" />
                      <span> {{ item.title }} </span>
                      <ChevronRight
                        class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      <SidebarMenuSubItem v-for="subItem in item.children" :key="subItem.title">
                        <SidebarMenuSubButton as-child>
                          <NuxtLink v-if="subItem.routeName" :to="{ name: subItem.routeName }"
                            exact-active-class="text-primary-800 font-semibold bg-zinc-100">
                            <span> {{ subItem.title }}</span>
                          </NuxtLink>
                          <span v-else>
                            <div class="flex space-x-1">
                              <span> {{ subItem.title }}</span>
                              <div class="text-[8px] text-blue-600">Bientôt disponible</div>
                            </div>
                          </span>

                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </SidebarMenuItem>
              </Collapsible>
              <SidebarMenuItem v-else>
                <SidebarMenuButton as-child v-if="item.routeName !== undefined">
                  <NuxtLink :to="{ name: item.routeName }"
                    exact-active-class="text-primary-800 font-semibold bg-zinc-100">
                    <component :is="item.icon" />
                    <span>{{ item.title }}</span>
                  </NuxtLink>
                </SidebarMenuButton>
                <SidebarMenuButton as-child v-else>
                  <NuxtLink :to="{ name: item.routeName }">
                    <component :is="item.icon" />
                    <span>{{ item.title }}</span>
                    <span class="text-[8px] text-blue-600">bientot disponible</span>
                  </NuxtLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </span>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <SidebarMenuButton size="lg"
                  class="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
                  <Avatar class="h-8 w-8 rounded-lg">
                    <AvatarImage :alt="userInfo?.last_name" src="" />
                    <AvatarFallback class="rounded-lg">
                      {{ userInfo?.first_name?.charAt(0) }}{{ userInfo?.last_name?.charAt(0) }}
                    </AvatarFallback>
                  </Avatar>
                  <div class="grid flex-1 text-left text-sm leading-tight">
                    <span class="truncate font-semibold">{{ userInfo?.first_name }} {{ userInfo?.last_name }}</span>
                    <span class="truncate text-xs">{{ supabaseUser?.email }}</span>
                  </div>
                  <ChevronsUpDown class="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" side="bottom"
                align="end" :side-offset="4">
                <DropdownMenuLabel class="p-0 font-normal">
                  <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <Avatar class="h-8 w-8 rounded-lg">
                      <AvatarImage src="data.user.avatar" :alt="userInfo?.last_name" />
                      <AvatarFallback class="rounded-lg">
                        {{ userInfo?.first_name?.charAt(0) }}{{ userInfo?.last_name?.charAt(0) }}
                      </AvatarFallback>
                    </Avatar>
                    <div class="grid flex-1 text-left text-sm leading-tight">
                      <span class="truncate font-semibold">{{ userInfo?.first_name }} {{ userInfo?.last_name }}</span>
                      <span class="truncate text-xs">{{ supabaseUser?.email }}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <!-- <DropdownMenuGroup>
                  <DropdownMenuItem>
                    <Sparkles />
                    Upgrade to Pro
                  </DropdownMenuItem>
                </DropdownMenuGroup> -->
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem>
                    <BadgeCheck />
                    Account
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Settings />
                    Paramètres
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Bell />
                    Notifications
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <LogOut />
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
    <SidebarInset>
      <header
        class="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
        <div class="flex items-center gap-2 px-4">
          <SidebarTrigger class="-ml-1" />
          <Separator orientation="vertical" class="mr-2 h-4" />
          {{ route.meta.title }}

        </div>
      </header>
      <slot />
    </SidebarInset>
  </SidebarProvider>
</template>
